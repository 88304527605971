import React, { useState } from 'react'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

import { MainLayout } from '@common/layouts'

const Wrapper = styled.section`
  ${tw`flex flex-col`}
`

const Center = styled.div`
  ${tw`relative mx-auto max-w-screen-xl`}
  ${tw`md:(h-auto)`}
`

const StepTitle = styled.p`
  ${tw`font-bold mb-4`}
`

const Conditions = styled.div`
  background-color: #f7f4f4;
  ${tw`flex flex-col pt-16 pb-8`}
  ${tw`md:(pt-24 pb-10)`}
`

const Button = styled(Link)`
  background-color: #ffe373;
  ${tw`flex items-center justify-center text-sm font-semibold min-w-40 h-12 px-4 mb-6 rounded text-black tracking-wider uppercase border-2 border-transparent outline-none transition-colors duration-200 leading-none cursor-pointer`}
  ${tw`hover:(transition-colors bg-white opacity-100)`}
  ${({ hasborder }) =>
    hasborder &&
    css`
      border-color: #ffe373;
    `}
`

const TextWrapper = styled.div`
  ${tw`flex flex-col items-center justify-between z-10 px-4 pt-12 text-white`}
  ${tw`md:(absolute inset-0 h-full ml-12 items-start justify-center)`}
`

const Title = styled.h1`
  ${tw`text-3xl font-semibold tracking-tight leading-tight mb-6 text-center`}
  ${tw`md:(text-4xl max-w-md text-left)`}
  ${tw`lg:(max-w-xl)`}
`

const ExtraText = styled.h3`
  ${tw`text-xl tracking-tight leading-tight my-6 text-center`}
  ${tw`md:(text-xl max-w-xl text-left)`}
  ${tw`lg:(max-w-xl)`}

  & > a {
    ${tw`underline`}
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  ${tw`bg-right-bottom flex items-start justify-center w-full pb-10`}
  ${tw`md:(h-auto py-0 items-center justify-start h-116)`}
  height: 100vw;
`

const WufooForm = ({ isFormOpen }) => {
  const styles = {
    true: tw`w-full md:(w-1/2 overflow-auto)`,
    false: tw`hidden`,
  }

  return (
    <div css={styles[isFormOpen]}>
      <iframe
        height="707"
        title="Embedded Wufoo Form"
        allowtransparency="true"
        frameborder="0"
        scrolling="no"
        style={{ width: '100%', border: 'none' }}
        src="https://hellomd.wufoo.com/embed/z1m61y00nqgnwd/"
      ></iframe>
    </div>
  )
}

const SeniorCare = () => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "get-started/seniorcare/hero.jpeg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1899) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroMobile: file(
          relativePath: { eq: "get-started/seniorcare/hero-m.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iconCondition: file(
          relativePath: { eq: "get-started/icon-condition.svg" }
        ) {
          publicURL
        }
      }
    `
  )

  const conditions = [
    'CHRONIC PAIN',
    'PTSD',
    'DEPRESSION',
    'ARTHRITIS',
    'INSOMNIA',
    'OPIOID DEPENDENCY',
  ]

  return (
    <MainLayout>
      <Wrapper>
        <section style={{ backgroundColor: '#d3a3b5' }}>
          <Center>
            <TextWrapper>
              {!isFormOpen && (
                <>
                  <Title>
                    The convenience of a house call, without coming to your
                    house.
                  </Title>
                  {/* <Button
                    onClick={() => {
                      setIsFormOpen((value) => !value)
                      // eslint-disable-next-line no-undef
                      z1m61y00nqgnwd.display()
                    }}
                  >
                    GET STARTED
                  </Button> */}
                  <Button to="https://mymedi.hellomd.com">
                    GET STARTED
                  </Button>
                  <ExtraText>
                    From the privacy of your own home, ask your questions about
                    medical cannabis and get the sound medical advices you need.
                    If its right for you, then shop from a trusted source -
                    MyMedi.
                  </ExtraText>
                </>
              )}
              <WufooForm isFormOpen={isFormOpen} />
            </TextWrapper>
            <StyledBackgroundImage
              Tag="section"
              fluid={[
                data.heroMobile.childImageSharp.fluid,
                {
                  ...data.hero.childImageSharp.fluid,
                  media: `(min-width: 768px)`,
                },
              ]}
              backgroundColor="#d3a3b5"
            />
          </Center>
        </section>
        <Center tw="flex flex-col items-center my-10 md:(my-16)">
          <ul tw="flex flex-col justify-between px-4 md:(flex-row)">
            <li tw="flex flex-1 flex-col items-center justify-between mb-16">
              <StepTitle>Welcome Call</StepTitle>
              <p tw="text-center px-10 md:(text-lg px-0)">
                Our friendly staff will call you to gather personal information,
                some medical history and schedule your consult.
              </p>
            </li>
            <li tw="flex flex-1 flex-col items-center justify-between mb-16 md:(mx-10)">
              <StepTitle>Practitioner Call</StepTitle>
              <p tw="text-center px-10 md:(text-lg px-0)">
                Our licensed practitioner will call you at home to learn more
                about you and discuss medical cannabis.
              </p>
            </li>
            <li tw="flex flex-1 flex-col items-center justify-between mb-16">
              <StepTitle>Shoppers Call</StepTitle>
              <p tw="text-center px-10 md:(text-lg px-0)">
                If authorized, we’ll connect you with a specialist at MyMedi
                to help you select products and arrange delivery.
              </p>
            </li>
          </ul>
        </Center>
        <div>
          <Conditions>
            <Center tw="flex flex-col w-full px-4">
              <div tw="flex flex-col w-full items-start justify-between mb-16 md:(flex-row mb-0)">
                <div tw="flex flex-col items-start md:(w-4/12 mr-16)">
                  <h2 tw="text-3xl font-bold mb-8 md:(text-4xl)">
                    Our knowledgeable health practitioners can advise you on how
                    cannabis may help alleviate symptoms related to a number of
                    health conditions, including:
                  </h2>
                </div>
                <ul tw="flex-1 grid grid-cols-1 gap-8 md:(grid-cols-2)">
                  {conditions.map((condition) => (
                    <li
                      key={condition}
                      tw="flex items-center text-2xl uppercase font-bold"
                    >
                      <img
                        tw="mr-6"
                        src={data.iconCondition.publicURL}
                        alt="Condition Icon"
                      />
                      {condition}
                    </li>
                  ))}
                </ul>
              </div>
            </Center>
          </Conditions>
        </div>
      </Wrapper>
    </MainLayout>
  )
}

export default SeniorCare
